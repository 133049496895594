export default [
    {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
      path: '/main',
      redirect: '/',
      component: () => import('@/layouts/Main.vue'),
      children: [
        {
          path: '/',
          name: 'dashboard',
          component: () => import('@views/Dashboard.vue'),
          meta: {
            title: 'Dashboard',
            authRequired: true,
          }
        },
        {
          path: '/account',
          name: 'account',
          component: () => import('@views/account/UserEdit.vue'),
          meta: {
            title: 'Account',
            authRequired: true,
          },
        },
        {
          path: '/allocations',
          name: 'allocations',
          component: () => import('@views/allocations/Allocations.vue'),
          meta: {
            title: "Allocations",
            authRequired: true,
          }
        },
        {
          path: '/allocations/:allocationId(\\d+)',
          name: 'allocation-show',
          component: () => import('@views/allocations/AllocationShow.vue'),
          meta: {
            title: "View Allocation",
            authRequired: true,
          },
        },
        {
          path: '/allocated-items/:itemId(\\d+)',
          name: 'allocated-item-show',
          component: () => import('@views/allocations/AllocatedItemShow.vue'),
          meta: {
            title: "View Allocated item",
            authRequired: true,
          },
        },
        {
          path: '/hospitals',
          name: 'hospitals',
          component: () => import('@views/hospitals/Hospitals.vue'),
          meta: {
            title: "Hospitals",
            authRequired: true,
          }
        },
        {
          path: '/hospitals/:hospitalId(\\d+)',
          name: 'hospital-show',
          component: () => import('@views/hospitals/HospitalShow.vue'),
          meta: {
            title: "View Hospital",
            authRequired: true,
          },
        },
        {
          path: '/notifications',
          name: 'notifications',
          component: () => import('@views/notifications/NotificationList.vue'),
          meta: {
            title: "Notifications",
            authRequired: true,
          }
        }
      ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
        path: '/full',
        component: () => import('@/layouts/Full.vue'),
        children: [
          {
            path: '/login',
            name: 'login',
            component: () => import('@/views/pages/Login.vue'),
            meta: {
              guestRequired: true,
              title: "Login"
            }
          },
          {
            path: '/error-404',
            name: 'error-404',
            component: () => import('@/views/pages/Error404.vue'),
            meta: {
              title: "404 - Page not Found"
            }
          },
          {
            path: '/error-500',
            name: 'error-500',
            component: () => import('@/views/pages/Error500.vue'),
            meta: {
              title: "500 - Server error"
            }
          },
        ]
    },
    // Redirect to 404 page, if no match found
    {
        path: '/:any(.*)',
        redirect: '/error-404'
    },

]